import {inject} from '@angular/core';
import { HttpErrorResponse, HttpContextToken, HttpInterceptorFn,
} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthService} from '../auth.service';
import {endpoints} from '../../../environments/environment';

export const BYPASS_AUTH = new HttpContextToken(() => false);


export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken && !req.context.get(BYPASS_AUTH)) {
    const cloned = req.clone({
      headers: req.headers.set('Authorization', accessToken),
    });

    return next(cloned).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && cloned.url !== endpoints.auth.refresh) {
          return authService.refresh().pipe(
            switchMap((refreshResponse) => {
              const cloned = req.clone({
                headers: req.headers.set('Authorization', refreshResponse.accessToken),
              });
              return next(cloned);
            }),
            catchError((error: HttpErrorResponse) => {
              return throwError(error);
            }),
          );
        }
        return throwError(error);
      }),
    );
  } else {
    return next(req);
  }
}
